<template>
  <div class="card-detail">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container fluid class="checkout-title">
      <CategoryTitle :category="category" :showOnMobile="true" />
    </v-container>
    <v-container fluid class="pt-0 mobile-no-padding">
      <v-row>
        <v-col cols="12" md="8" class="pt-0">
          <div class="mb-5 px-5 px-sm-0">
            <cart-info-service-card
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              class="w-100"
              @edit="goToServiceSelector"
            />
          </div>
          <div class="mb-5 px-5 px-sm-0">
            <cart-info-address-card
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              class="w-100"
              @edit="openAddressSelector"
            />
          </div>
          <div class="mb-5 px-5 px-sm-0">
            <cart-info-timeslot-card
              :shippingAddress="cart.shippingAddress"
              :timeslot="cart.timeslot"
              :editable="editableTimeslot"
              class="w-100 cart-info-timeslot-card"
              :value="timeslot"
              @edit="openTimeslotSelector"
            />
          </div>

          <!-- <v-row justify="space-between" class="mb-3 px-3 px-sm-0"> -->
          <div class="mb-3">
            <!-- <v-col cols="12" class="px-0 px-md-3"> -->
            <CartItemList class="items-list" :isCheckout="true" />

            <v-alert
              v-if="error"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
            <!-- </v-col> -->

            <!-- <v-col cols="12"> -->

            <!-- </v-col> -->
            <!-- </v-row> -->
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pt-0">
          <v-card>
            <v-card-text v-if="profileLevel < minProfileLevel">
              <CheckoutCompleteProfile />
            </v-card-text>

            <CheckoutSummary
              :orderCart="cart"
              @removeGiftCode="loadGiftCertificates"
            />

            <v-card-text>
              <div class="accept-alternatives-container mb-6">
                <span class="text-h5">
                  {{ $t("cartDetail.acceptAlternativesTitle") }}
                </span>
                <span>
                  {{ $t("cartDetail.acceptAlternativesSubtitle") }}
                </span>
                <v-checkbox
                  dense
                  color="primary"
                  hide-details
                  v-model="acceptAlternatives"
                  class="accept-alternatives pt-0 mr-2"
                >
                  <template v-slot:label>
                    <span>{{
                      $t("cartDetail.acceptAlternativesCheckbox")
                    }}</span>
                    <v-tooltip
                      :open-on-hover="false"
                      :open-on-click="true"
                      bottom
                      max-width="500"
                      content-class="alternative-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>
                            $info
                          </v-icon>
                        </v-btn>
                      </template>
                      <span class="alternative-tooltip-text">{{
                        $t("cartDetail.acceptAllAlternativesTooltip")
                      }}</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </div>

              <DeliveryFee class="mt-5" :showList="true" />

              <category-block
                :target="category"
                :container="false"
                position="position3"
                class="category-block category-block-3 small-product-card"
              />
              <category-block
                :target="category"
                :container="false"
                position="position4"
                class="category-block category-block-4 small-product-card"
              />
            </v-card-text>

            <v-card-actions class="d-none d-md-flex">
              <v-btn
                class="mt-5 w-100"
                color="primary"
                x-large
                :block="$vuetify.breakpoint.xs"
                depressed
                :disabled="
                  profileLevel < minProfileLevel || cart.totalItems == 0
                "
                :loading="loading"
                @click="goToCheckout"
              >
                <span>{{ $t("cartDetail.goToCheckoutBtn") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <div
        class="checkout-actions d-flex justify-start align-center flex-column flex-md-row px-5 px-sm-0 mt-2"
      >
        <v-btn
          class="mr-0 mr-md-2 mb-2 mb-md-0 order-2"
          color="primary"
          x-large
          outlined
          depressed
          :to="{ name: 'Home' }"
        >
          <span>{{ $t("checkout.continueShoppingBtn") }}</span>
        </v-btn>
        <v-btn
          class="mr-0 mr-md-2 mb-2 mb-md-0 order-3"
          color="primary"
          x-large
          outlined
          depressed
          @click="emptyCartConfirm"
        >
          <span>{{ $t("checkout.emptyCartBtn") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="mr-0 mr-md-2 mb-2 mb-md-0 order-4"
          x-large
          depressed
          outlined
          @click="addAllToFavorites"
        >
          <span>{{ $t("checkout.addToListBtn") }}</span>
        </v-btn>
        <v-btn
          class="margin-left-auto mb-2 mb-md-0 order-1 order-md-5"
          color="primary"
          x-large
          depressed
          :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
          :loading="loading"
          @click="goToCheckout"
        >
          <span>{{ $t("cartDetail.goToCheckoutBtn") }}</span>
        </v-btn>
      </div>

      <category-block
        :target="category"
        :container="false"
        position="position2"
        class="category-block category-block-2"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.card-detail {
  .qty-wrap {
    max-width: 160px;
  }
  .margin-left-auto {
    margin-left: auto;
  }
  .checkout-actions {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 67%;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-btn {
        width: 100%;
      }
    }
  }
}
.alternative-tooltip {
  line-height: 1.3rem;
  .alternative-tooltip-text {
    font-size: 0.9rem;
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapState, mapGetters } from "vuex";

import get from "lodash/get";
// import reduce from "lodash/reduce";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";

export default {
  name: "Cart",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CheckoutCompleteProfile,
    CartItemList,
    DeliveryFee,
    CartInfoServiceCard,
    CartInfoAddressCard,
    CartInfoTimeslotCard
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      dialogInstance: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    acceptAlternatives: {
      get() {
        let value = true;
        this.cart.cartItems.forEach(cartItem => {
          if (
            get(cartItem, "cartItemInfo.accept_alternatives", "true") == "false"
          ) {
            value = false;
          }
          return value;
        });
        // reduce(
        //   this.cart.cartItems,
        //   function(value, cartItem) {
        //     return (value && cartItem.cartItemInfo.accept_alternatives) || true;
        //   },
        //   true
        // );
        // this.cart.cartItems.forEach(cartItem => {
        //   if (
        //     get(cartItem, "cartItemInfo.accept_alternatives", "true") ==
        //     "false"
        //   ) {
        //     value = false;
        //   }
        //   return value;
        // });
        return value;
      },
      set(value) {
        this.setCartItemInfo({
          items: this.cart.cartItems,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    giftCertificatesReceived() {
      return this.cart.user?.giftCertificatesReceived;
    }
  },

  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart",
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    goToServiceSelector() {
      this.$router.push({
        name: "DeliveryServiceView",
        query: { back: this.$route.fullPath }
      });
    },
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async goToCheckout() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Checkout"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    async removeGift(gift) {
      await this.removeGiftCode(gift);
      this.loadGiftCertificates();
    }
  },
  async mounted() {
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    this.checkOsx();
    this.loadGiftCertificates();
  }
};
</script>
